// src/projects/ITeaMoa/Post1.js

import React from 'react';

function Post1() {
  return (
    <div>
      <h2>Post 1: 프로젝트 설계 - 요구사항 분석: SRS, 요구사항 명세서</h2>
      {/* Add detailed content for Post 1 here */}
    </div>
  );
}

export default Post1;
