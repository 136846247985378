//src/components/Header.js

function Header() {

  return (
    <div className= "header">
      <h1>Hi! I’m Bohyeon Park 👩🏻‍💻</h1>
    </div>
  );
}

export default Header;
