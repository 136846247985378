// src/projects/ITeaMoa/Post2.js

import React from 'react';

function Post2() {
  return (
    <div className="project-post">
      <h1>Introduction to ITeaMoa</h1>
      <p>This is the detailed content for the first post of the ITeaMoa project...</p>
    </div>
  );
}

export default Post2;
